.campaign-list-campaign-info-grid {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  align-items: center;

  display: grid;
  grid-template-columns: max-content 1fr 1fr max-content;
  grid-template-rows: auto;
  grid-template-areas: "name info1 info2 actions";
  column-gap: 10px;
}

.campaign-list-campaign-info-grid:hover {
  background-color: #f5f5f5;
}

@media screen and (max-width: 991px) {
  .campaign-list-campaign-info-grid {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "name name actions"
      "info1 info2 actions";
  }
}