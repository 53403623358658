#map-details-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px 20px;
}

.map-details-col-span-2 {
  grid-column: span 2;
}

.map-details-row-span-2 {
  grid-row: span 2;
}

.golden-details-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: max-content 1fr;
  gap: 10px 20px;

  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.golden-details-proof {
  grid-column: 2;
  grid-row: span 2;
}

.d-contents {
  display: contents;
}

@media (max-width: 991px) {
  #map-details-grid {
    grid-template-columns: 1fr;
  }

  .map-details-col-span-2 {
    grid-column: unset;
  }

  .golden-details-grid {
    grid-template-columns: 1fr;
  }

  .golden-details-proof {
    grid-column: unset;
    grid-row: unset;
  }
}