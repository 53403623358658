.player-list-outer-grid {
  display: grid;
  grid-template-columns: 20fr 1px 20fr 20fr 1px 25fr;
  grid-template-rows: 1fr 1px 1fr;
  grid-template-areas:
    "name sep1 maps     maps    sep3  golden_tiers"
    "name sep1 sep2     sep2    sep3  golden_tiers"
    "name sep1 goldens  goldens sep3  golden_tiers";
  place-items: center;
  gap: 3px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px;
}

.player-list-outer-grid:hover {
  background-color: #f5f5f5;
}

.players-list-tier-badges-container {
  grid-area: golden_tiers;
  padding: 10px;
  height: 100%;
  justify-self: start;
}

.players-list-tier-badges {
  flex-wrap: wrap;
}

.player-list-username-grid {
  display: grid;
  grid: 1fr 1fr / 1fr 1fr;
  grid-template-areas:
    "icon icon"
    "username username";

}

.player-list-username-grid>span {
  font-size: 1.25rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: center;
}

/* on lower screen resolutions, change the grid template */
@media (max-width: 991px) {
  .player-list-outer-grid {
    grid-template-columns: 8fr 1px 20fr 20fr;
    grid-template-areas:
      "name sep1 maps     maps"
      "name sep1 sep2     sep2"
      "name sep1 goldens  goldens";
  }

  .players-list-tier-badges-container {
    display: none;
  }

  .player-list-username-grid>span {
    font-size: .7rem;
  }
}