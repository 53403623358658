#index-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

@media screen and (max-width: 991px) {
  #index-container {
    grid-template-columns: 1fr;
  }
}

.index-item {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  border: 1px solid #bbb;
  align-self: start;
}

.markdown-image {
  max-width: 100%;
  height: auto;

  border: 1px solid #bbb;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.changelog-item ul {
  list-style-type: none;
}

.changelog-item>ul {
  padding-left: 0;
}

.changelog-item ul:last-child {
  margin-bottom: 0;
}