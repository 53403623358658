/* html, body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
} */

.celeste-stats-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.celeste-stats-container .box {
  width: 25%;
  height: 100%;

  padding: 1rem;
  border-radius: 5px;
  border: 1px solid black;
}

.celeste-stats-container h1 {
  font-size: 3rem;
  margin: 0;
  text-align: center;
}

.celeste-stats-container table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;
  text-align: center;
}
.celeste-stats-container table,
.celeste-stats-container th,
.celeste-stats-container td {
  border: 1px solid black;
}
