.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 72px;
  margin-bottom: 10px;
}
.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}
.board-row {
  display: flex;
  flex-direction: row;
}
.square {
  background-color: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 90px;
  font-weight: bold;
  line-height: 100px;
  height: 100px;
  width: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
}
