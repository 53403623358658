.golden-run-display {
  width: 400px;
  padding: 3px 10px;
  margin: 0px 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  user-select: none;

  border-radius: 20px;
}

.golden-run-display:not(:last-child):not(.golden-run-display-toggle) {
  margin-bottom: 5px;
}

.golden-run-display>.clear {
  flex: 10;
}

.golden-run-display>.description {
  flex: 40;
}

.golden-run-display>.time {
  flex: 40;
  align-self: center;
}

.golden-run-display>.deaths {
  flex: 30;
  align-self: center;
}

.golden-run-display>.tier {
  flex: 28;
}


.might-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.might-overflow:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-word;
}

.might-overflow-abs {
  position: relative;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.might-overflow-abs:hover {
  overflow: visible;
}

.might-overflow-abs:hover span {
  position: absolute;
  background-color: inherit;

  box-shadow: 0 0 4px 0 black;
  /* border-radius: 1px; */

  transform: translateY(-50%);
}