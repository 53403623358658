.colored-dropdown-item.primary {
  color: var(--bs-light);
  background-color: hsl(var(--dd-primary));
}

.colored-dropdown-item.primary:hover {
  color: var(--bs-light);
  background-color: hsl(var(--dd-primary) / .8);
}


.colored-dropdown-item.success {
  color: var(--bs-light);
  background-color: hsl(var(--dd-success));
}

.colored-dropdown-item.success:hover {
  color: var(--bs-light);
  background-color: hsl(var(--dd-success) / .8);
}


.colored-dropdown-item.info {
  color: var(--bs-light);
  background-color: hsl(var(--dd-info));
}

.colored-dropdown-item.info:hover {
  color: var(--bs-light);
  background-color: hsl(var(--dd-info) / .8);
}


.colored-dropdown-item.warning {
  color: var(--bs-light);
  background-color: hsl(var(--dd-warning));
}

.colored-dropdown-item.warning:hover {
  color: var(--bs-light);
  background-color: hsl(var(--dd-warning) / .8);
}


.colored-dropdown-item.secondary {
  color: var(--bs-light);
  background-color: hsl(var(--dd-secondary));
}

.colored-dropdown-item.secondary:hover {
  color: var(--bs-light);
  background-color: hsl(var(--dd-secondary) / .8);
}


.colored-dropdown-item.danger {
  color: var(--bs-light);
  background-color: hsl(var(--dd-danger));
}

.colored-dropdown-item.danger:hover {
  color: var(--bs-light);
  background-color: hsl(var(--dd-danger) / .8);
}

:root {
  --dd-primary: 216 98% 52%;
  --dd-secondary: 208 7% 46%;
  --dd-success: 152 69% 31%;
  --dd-info: 190 90% 50%;
  --dd-warning: 45 100% 51%;
  --dd-danger: 354 70% 54%;
}

.gb-api-has-content {
  text-decoration: underline;
  cursor: pointer;
  /*color: var(--bs-link-color);*/
}