.player-data-container {
  margin: 2.5%;

  width: 95%;
  display: flex;
  flex-direction: column;
}

.player-data-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.player-data-header>h1 {
  flex-grow: 1;
  font-size: 3rem;
  margin: 0;
  text-align: center;
}

.sort-box {
  padding: 0.5rem 0px 0.5rem 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 255, 0.255);
  font-size: large;
  font-weight: bold;
}

.sort-box:hover {
  border: 1px solid rgba(0, 0, 255, 0.7);
}

.sort-box>select {
  font-size: large;
  margin: 0 0.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.player-general-stats {
  margin-bottom: 1rem;
}

.player-general-table {
  min-width: 20%;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
}

.player-general-table td:first-child {
  border-right: 1px solid black;
}

.player-general-table tr:not(:last-child) {
  border-bottom: 1px dashed black;
}

.actions-cell>* {
  padding: 0 5px;
}

#player-data-table td {
  vertical-align: middle;
  padding: 5px 5px;
}



.general-stats-grid {
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(2, max-content 1px) max-content;
  grid-template-areas:
    "maps sep1 goldens sep2 total";
  /* place-items: center; */
  gap: 5px 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 10px;
}

.general-stats-inner-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(4, 10fr);
  gap: 0px 10px;
}

@media (max-width: 991px) {
  .general-stats-grid {
    grid-template-columns: unset;
    grid-template-rows: repeat(2, max-content 1px) max-content;
    grid-template-areas:
      "maps"
      "sep1"
      "goldens"
      "sep2"
      "total";
  }

  .general-stats-inner-grid {
    grid-template-columns: auto auto;
    grid-template-rows: unset;
  }
}