/* html {
  overflow-y: scroll;
}
@supports (scrollbar-gutter: stable) {
  html {
    overflow-y: auto;
    scrollbar-gutter: stable;
  }
} */

/* html {
  width: 100vw;
}
body {
  overflow-x: hidden;
  padding-right: 0 !important;
} */

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#main-outlet {
  overflow-y: auto;
  flex: 1;
}

@supports (scrollbar-gutter: stable) {
  #main-outlet {
    scrollbar-gutter: stable;
  }
}

.no-border {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  border: none !important;
}

.hover-underline {
  text-decoration: none;
}

.hover-underline:hover {
  text-decoration: underline;
}